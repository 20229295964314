import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import charterLogoTransparent from "../assets/charterSchoolsTrans.png";
import charterLogo from "../assets/logo.png";

import logout from "../assets/svg/logout.svg";
import siteTuturial from "../assets/svg/uaeFlag.svg";

import YellowArrow from "../assets/svg/charterIcons/ArrowYellow.svg";
import BlueArrow from "../assets/svg/charterIcons/ArrowBlue.svg";
import WhiteArrow from "../assets/svg/charterIcons/ArrowWhite.svg";

import RadialChildSelection from "../components/RadialChildSelection";

import { useMediaQuery } from "react-responsive";

import logoutYellow from "../assets/svg/charterIcons/logoutYellow.svg";
import logoutBlue from "../assets/svg/charterIcons/logoutBlue.svg";
import logoutWhite from "../assets/svg/charterIcons/logoutWhite.svg";

import AbsenceWhite from "../assets/svg/charterIcons/AbsenceWhite.svg";
import AbsenceYellow from "../assets/svg/charterIcons/AbsenceYellow.svg";
import AbsenceBlue from "../assets/svg/charterIcons/AbsenceBlue.svg";

import GalleryBlue from "../assets/svg/charterIcons/GalleryBlue.svg";
import GalleryWhite from "../assets/svg/charterIcons/GalleryWhite.svg";
import GalleryYellow from "../assets/svg/charterIcons/GalleryYellow.svg";

import resourcesBlue from "../assets/svg/charterIcons/resourcesBlue.svg";
import resourcesYellow from "../assets/svg/charterIcons/resourcesYellow.svg";
import resourcesWhite from "../assets/svg/charterIcons/resourcesWhite.svg";

import SchoolBlue from "../assets/svg/charterIcons/SchoolBlue.svg";
import SchoolYellow from "../assets/svg/charterIcons/SchoolYellow.svg";
import SchoolWhite from "../assets/svg/charterIcons/SchoolWhite.svg";

import dashboardBlue from "../assets/svg/charterIcons/dashboardBlue.svg";
import dashboardYellow from "../assets/svg/charterIcons/dashboardYellow.svg";
import dashboardWhite from "../assets/svg/charterIcons/dashboardWhite.svg";

import tutorialWhite from "../assets/svg/charterIcons/tutorialWhite.svg";
import tutorialYellow from "../assets/svg/charterIcons/tutorialYellow.svg";

import { useTranslation, Trans } from "react-i18next";
import TourTut from "./TourTut";
import URL from "../Settings.json";
import SideBarBtn from "./SideBarBtn";
import FooterBtn from "./FooterBtn";
const _Url = URL._Url;

function Sidebar({
  loggedInParent,

  viewState,
  setViewState,
  loggedIn,
  setloggedIn,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedChild_UserName,
  setSelectedChild_UserName,
  studentInfo,
  setStudentInfo,
  showSideBar,
  setShowSideBar,
  lang,
  setLang,
  minimized,
  setMinimized,
  logOut,
  token,
}) {
  const [childName, setChildName] = useState("");

  const getSideBarClass = () => {
    if (minimized) {
      return lang == "EN" ? "MiniSideBarClass" : "MiniSideBarClassAR";
    } else {
      return lang == "EN" ? "newSideBarClass" : "ARSideBarClass";
    }
  };

  const { t, i18n } = useTranslation();

  const changeLangTo = (langg) => {
    let font = null;
    const id = "dynamic-font";

    if (document.getElementById(id)) {
      font = document.getElementById(id);
    } else {
      font = document.createElement("link");
      font.rel = "stylesheet";
      font.id = id;
      document.head.appendChild(font);
    }

    if (langg == "AR") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      font.href = "./fonts/ARstylesheet.css";

      i18n.changeLanguage("ar");
      setLang("AR");
    } else {
      font.href = "./fonts/stylesheet.css";
      setLang("EN");
      i18n.changeLanguage("en");
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }
  };

  const toggleLang = () => {
    if (lang == "EN") {
      changeLangTo("AR");
    } else {
      changeLangTo("EN");
    }
  };
  const changeARabic = () => {
    if (lang == "EN") {
      changeLangTo("AR");
    }
  };
  const changeEnglish = () => {
    if (lang == "AR") {
      changeLangTo("EN");
    }
  };
  const [isTourOpen, setisTourOpen] = useState(false);
  useEffect(() => {
    if (loggedInParent) {
      loggedInParent && setisTourOpen(!loggedInParent.pP_SeenTutorial);
    } else {
      setisTourOpen(false);
    }
    changeLangTo("EN");
  }, [loggedInParent]);

  const onBtnClick = (view) => {
    setViewState(view);
    if (isMobile) {
      setShowSideBar(false);
    }
  };
  const isMobile = useMediaQuery({ query: "(max-width: 920px)" });
  const getClassName = (view) => {
    if (minimized) {
      return "minSideBarBtn ";
    } else {
      if (view == viewState) {
        return "sideBarButton selectedBtn  text-left";
      } else {
        return "sideBarButton  text-left";
      }
    }
  };
  const getIconClassName = (view) => {
    if (minimized) {
      return "miniSideBarIcon";
    } else {
      if (view == viewState) {
        return "SidebarIcon";
      } else {
        return "UnselectedSidebarIcon";
      }
    }
  };
  const getArrowClass = () => {
    if (minimized) {
      return lang == "EN" ? "OpenMenu" : "";
    } else {
      return lang == "EN" ? "" : "OpenMenu";
    }
  };





  const location = window.location;
  let text = location.toString();
  const PToken = text.split("?t=");

  const logOut2 = (view) => {
    if (PToken[1]) {
      window.location.href = "https://ctsadekdemo.onelogin.com/portal";
    } else {
      logOut();
    }
  };


  return (
    <div className="mobileSideWrapper">
      <TourTut
        isTourOpen={isTourOpen}
        setisTourOpen={setisTourOpen}
        setViewState={setViewState}
        loggedInParent={loggedInParent}
        lang={lang}
        token={token}
      />

      <div className={getSideBarClass()}>
        <div className="SidebarTop">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className="sidebarTopDiv"
              onClick={() => {
                setShowSideBar(false);
              }}
            >
              <img
                src={minimized ? BlueArrow : WhiteArrow}
                onMouseEnter={(e) => (e.currentTarget.src = YellowArrow)}
                onMouseLeave={(e) =>
                  (e.currentTarget.src = minimized ? BlueArrow : WhiteArrow)
                }
                className={getArrowClass()}
                onClick={() => setMinimized(!minimized)}
              ></img>
            </div>
            <div className="logo mobileTopLogo">
              <img
                src={charterLogoTransparent}
                style={{ width: "200px", height: "80px" }}
                className={
                  minimized
                    ? lang == "EN"
                      ? "rotatedLogo"
                      : "rotatedLogoAR"
                    : "scale08"
                }
              />
            </div>
          </div>
          {/* <div className="parentName">
            {loggedInParent &&
              !minimized &&
              (lang == "EN"
                ? loggedInParent.english_FirstName
                : loggedInParent.arabic_FirstName)}
          </div> */}
          <div
            style={{
              position: "absolute",
              left: "70px",
              top: "100px",
              display: minimized ? "none" : "block",
            }}
          >
            {loggedInParent && (
              <RadialChildSelection
                ParentId={loggedInParent.parent_Id}
                selectedChild_Id={selectedChild_Id}
                setSelectedChild_Id={setSelectedChild_Id}
                selectedChild_UserName={selectedChild_UserName}
                setSelectedChild_UserName={setSelectedChild_UserName}
                setChildName={setChildName}
                studentInfo={studentInfo}
                setStudentInfo={setStudentInfo}
                ParentGender={loggedInParent.parent_Type}
                loggedInParent={loggedInParent}
                lang={lang}
                token={token}
              />
            )}
          </div>
        </div>

        <div className="main-menu">
          <div
            className={
              minimized
                ? lang == "EN"
                  ? "MinimizedIcons"
                  : "MinimizedIconsAr"
                : "SidebarIconsUL"
            }
          >
            <SideBarBtn
              onBtnClick={onBtnClick}
              BtnId="DashboardSideBar_Btn"
              index={0}
              activeView={viewState}
              Text={t("sideBar.Dashboard")}
              className={getClassName(0)}
              IconClass={getIconClassName(0)}
              WhiteIcon={dashboardWhite}
              YellowIcon={dashboardYellow}
              BlueIcon={dashboardBlue}
              minimized={minimized}
            />
            <SideBarBtn
              onBtnClick={onBtnClick}
              BtnId="SchoolProfile_Btn"
              index={1}
              activeView={viewState}
              Text={t("sideBar.SchoolProfile")}
              className={getClassName(1)}
              IconClass={getIconClassName(1)}
              WhiteIcon={SchoolWhite}
              YellowIcon={SchoolYellow}
              BlueIcon={SchoolBlue}
              minimized={minimized}
            />
            <SideBarBtn
              onBtnClick={onBtnClick}
              BtnId="Attendance_Btn"
              index={2}
              activeView={viewState}
              Text={t("sideBar.Attendance")}
              className={getClassName(2)}
              IconClass={getIconClassName(2)}
              WhiteIcon={AbsenceWhite}
              YellowIcon={AbsenceYellow}
              BlueIcon={AbsenceBlue}
              minimized={minimized}
            />

            <SideBarBtn
              onBtnClick={onBtnClick}
              BtnId="Resources_Btn"
              index={3}
              activeView={viewState}
              Text={t("sideBar.Resources")}
              className={getClassName(3)}
              IconClass={getIconClassName(3)}
              WhiteIcon={resourcesWhite}
              YellowIcon={resourcesYellow}
              BlueIcon={resourcesBlue}
              minimized={minimized}
            />
            {/* <SideBarBtn
              onBtnClick={onBtnClick}
              BtnId="MediaGallery_Btn"
              index={4}
              activeView={viewState}
              Text={t("sideBar.MediaGallery")}
              className={getClassName(4)}
              IconClass={getIconClassName(4)}
              WhiteIcon={GalleryWhite}
              YellowIcon={GalleryYellow}
              BlueIcon={GalleryBlue}
              minimized={minimized}
            /> */}
          </div>
        </div>

        {!minimized && (
          <FooterBtn
            onBtnClick={() => setisTourOpen(true)}
            BtnId="SiteTutorialBtn"
            Text={t("sideBar.SiteTutorial")}
            minimized={minimized}
            WhiteIcon={tutorialWhite}
            YellowIcon={tutorialYellow}
            Bottom="120px"
          />
        )}
        <FooterBtn
          onBtnClick={logOut2}
          BtnId="logOutBTn"
          Text={t("sideBar.Logout")}
          minimized={minimized}
          WhiteIcon={logoutWhite}
          YellowIcon={logoutYellow}
          Bottom="70px"
        />
        {/* <div className="sideBarTutorial">
          <div
            id="SiteTutorialBtn"
            onClick={() => setisTourOpen(true)}
            className="footerBtn  text-left"
          >
            <img src={siteTuturial} className="siteHelp"></img>
            <div className="footerSideBarText bottomText"> </div>
          </div>
        </div> */}
        {/* <div className="sideBarFooter">
          <div onClick={logOut} className="footerBtn  text-left">
            <img src={logout} className="logoutImg"></img>
            <div className="footerSideBarText bottomText">
              {t("sideBar.Logout")}
            </div>
          </div>
        </div> */}
        <div class="langFooter">
          <div class="langBTN">
            <span
              class={lang == "EN" ? "ActiveLang" : "InActive"}
              onClick={changeEnglish}
            >
              {" "}
              EN
            </span>{" "}
            |{" "}
            <span
              class={lang == "AR" ? "ActiveLang" : "InActive"}
              onClick={changeARabic}
            >
              العربي
            </span>
          </div>
        </div>
      </div>
      {/* <div className="mobileOnly SideBarRight">
        <img
          src={charterLogoTransparent}
          className="mobileLogoHeader"
          style={{
            width: "120px",
            height: "44px",
            right: "0px",
            position: "absolute",
          }}
        />
      </div> */}
    </div>
  );
}

export default Sidebar;
